:root {
  --ion-safe-area-top: 20px;
  --ion-safe-area-bottom: 22px;
}
.swiper-container {
  height: 100%;
}
.swiper-slide {
  display: block;
  height: auto;
  padding: 1rem;
}
ion-slide > h2 {
  margin-top: 2.8rem;
}
ion-slide > img {
  max-height: 50%;
  max-width: 60%;
  margin: 36px 0;
}